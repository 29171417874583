import { SVGProps } from 'react';
import styled from 'styled-components';

import EyeIcon from './icons/eye';
import GlassIcon from './icons/glass';
import HomeIcon from './icons/home';
import MarketingIcon from './icons/marketing';
import AdminIcon from './icons/admin';
import SdmIcon from './icons/sdm';
import EyeforceIcon from './icons/eyeforce';
import CoopIcon from './icons/coop';
import ArticleIcon from './icons/article';
import BellIcon from './icons/bell';
import MenuIcon from './icons/menu';
import CoopDollarIcon from './icons/coopDollar';
import CloseIcon from './icons/close';
import { IconWrapper } from './styled';
import LeftArrowIcon from './icons/leftArrow';
import ChevronLeftIcon from './icons/chevronLeft';
import ChevronRightIcon from './icons/chevronRight';
import DownloadIcon from './icons/download';
import PlusSquareIcon from './icons/plus-square';
import CopyIcon from './icons/copy';
import FilterIcon from './icons/filter';
import EyeforceServiceIcon from './icons/eyeforce-service';
import ChevronUpIcon from './icons/chevronUp';
import ChevronDownIcon from './icons/chevonDown';
import InfoIcon from './icons/info';
import DangerIcon from './icons/danger';
import UserIcon from './icons/user';
import SettingsIcon from './icons/settings';
import LogoutIcon from './icons/logout';
import AddressIcon from './icons/address';
import PhoneIcon from './icons/phone';
import GlobeIcon from './icons/globe';
import CalendarIcon from './icons/calendar';
import ShoppingBagIcon from './icons/shopping-bag';
import { Dealer } from '../../molecules/CartHeader/CartHeader.stories';
import DealerPortalHostedCodeIcon from './icons/dealerPortalHostedCode';
import VideoUrlIcon from './icons/videoUrl';

export type IconName =
  | 'home'
  | 'glass'
  | 'eye'
  | 'marketing'
  | 'admin'
  | 'sdm'
  | 'eyeforce'
  | 'coop'
  | 'article'
  | 'bell'
  | 'menu'
  | 'coopDollar'
  | 'close'
  | 'leftArrow'
  | 'chevronLeft'
  | 'chevronRight'
  | 'chevronUp'
  | 'chevronDown'
  | 'download'
  | 'plusSquare'
  | 'copy'
  | 'filter'
  | 'eyeforceService'
  | 'info'
  | 'danger'
  | 'user'
  | 'settings'
  | 'logout'
  | 'address'
  | 'phone'
  | 'globe'
  | 'calendar'
  | 'shoppingBag'
  | 'dealerPortalHostedCode'
  | 'videoUrl';

type IconRecord = Record<IconName, JSX.Element>;

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

type Color = RGB | RGBA | HEX;

interface IconProps {
  /**
   * Icon name selector
   */
  name: Partial<IconName>;
  /**
   * -
   */
  iconProps?: SVGProps<SVGSVGElement>;
  /**
   * Color of the icon
   */
  color?: Color;
  /**
   * Size of the icon
   */
  size: number;
  /**
   * The thickness of the stroke of SVG lines
   */
  thick?: number;
}

const Icon = (props: IconProps) => {
  const { color, size, thick, name } = props;

  const icons: IconRecord = {
    home: <HomeIcon {...props.iconProps} />,
    glass: <GlassIcon {...props.iconProps} />,
    eye: <EyeIcon {...props.iconProps} />,
    leftArrow: <LeftArrowIcon {...props.iconProps} />,
    marketing: <MarketingIcon {...props.iconProps} />,
    admin: <AdminIcon {...props.iconProps} />,
    sdm: <SdmIcon {...props.iconProps} />,
    eyeforce: <EyeforceIcon {...props.iconProps} />,
    coop: <CoopIcon {...props.iconProps} />,
    article: <ArticleIcon {...props.iconProps} />,
    bell: <BellIcon {...props.iconProps} />,
    menu: <MenuIcon {...props.iconProps} />,
    coopDollar: <CoopDollarIcon {...props.iconProps} />,
    close: <CloseIcon {...props.iconProps} />,
    chevronLeft: <ChevronLeftIcon {...props.iconProps} />,
    chevronRight: <ChevronRightIcon {...props.iconProps} />,
    chevronUp: <ChevronUpIcon {...props.iconProps} />,
    chevronDown: <ChevronDownIcon {...props.iconProps} />,
    download: <DownloadIcon {...props.iconProps} />,
    plusSquare: <PlusSquareIcon {...props.iconProps} />,
    copy: <CopyIcon {...props.iconProps} />,
    filter: <FilterIcon {...props.iconProps} />,
    eyeforceService: <EyeforceServiceIcon {...props.iconProps} />,
    info: <InfoIcon {...props.iconProps} />,
    danger: <DangerIcon {...props.iconProps} />,
    user: <UserIcon {...props.iconProps} />,
    settings: <SettingsIcon {...props.iconProps} />,
    logout: <LogoutIcon {...props.iconProps} />,
    address: <AddressIcon {...props.iconProps} />,
    phone: <PhoneIcon {...props.iconProps} />,
    globe: <GlobeIcon {...props.iconProps} />,
    calendar: <CalendarIcon {...props.iconProps} />,
    shoppingBag: <ShoppingBagIcon {...props.iconProps} />,
    dealerPortalHostedCode: <DealerPortalHostedCodeIcon {...props.iconProps} />,
    videoUrl: <VideoUrlIcon {...props.iconProps} />,
  };

  return (
    <IconWrapper color={color} size={size} thick={thick} data-testid='icon'>
      {icons[name]}
    </IconWrapper>
  );
};

export default Icon;
