import { SVGProps } from 'react';

const GlassIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.33 15.12h-.17c-2.13-.23-4.19-.97-5.98-2.12a13.58 13.58 0 0 1-4.15-4.15A13.935 13.935 0 0 1 .92 2.84c-.02-.26 0-.52.09-.76.08-.24.21-.46.38-.65s.38-.34.61-.44c.24-.1.48-.16.74-.16h2c.4 0 .87.15 1.21.44.34.29.56.69.62 1.13.08.61.23 1.2.44 1.77.12.33.15.68.08 1.03-.07.34-.24.66-.49.91l-.57.57c.83 1.34 1.96 2.46 3.29 3.29l.57-.57c.25-.25.56-.42.91-.49s.7-.05 1.03.08c.56.21 1.16.36 1.76.44a1.823 1.823 0 0 1 1.58 1.85v1.99c0 .25-.05.5-.15.74a1.802 1.802 0 0 1-1.09 1c-.19.06-.39.1-.59.1l-.01.01ZM4.75 1.83H2.74c-.12 0-.23.02-.34.07-.1.05-.2.12-.28.2-.08.09-.14.19-.17.3-.04.11-.05.23-.04.34.21 1.96.89 3.89 1.97 5.55.98 1.54 2.31 2.87 3.85 3.85 1.66 1.08 3.58 1.76 5.54 1.97.11.01.22 0 .33-.04.11-.04.21-.1.29-.17a.69.69 0 0 0 .2-.28c.05-.11.07-.22.07-.34v-2c0-.22-.06-.42-.2-.57a.828.828 0 0 0-.52-.29c-.68-.09-1.34-.26-1.98-.49a.836.836 0 0 0-.47-.04c-.16.03-.3.11-.41.22l-.85.85a.5.5 0 0 1-.6.08c-1.75-.99-3.19-2.44-4.19-4.19-.11-.2-.08-.44.08-.6l.85-.85c.11-.11.19-.25.22-.41.03-.16.02-.32-.04-.47-.24-.64-.4-1.31-.49-1.98a.838.838 0 0 0-.28-.51.82.82 0 0 0-.54-.2h.01Z'
        fill='#ADB5BD'
      />
    </svg>
  );
};

export default GlassIcon;
